/* ================================= Tab Css Start =========================== */
.common-tab {
    margin-bottom: 20px;
    gap: 16px;
    @include md-screen {
        gap: 8px;
    }
    .nav-item {
        border-bottom: 0;
        .nav-link {
            color: hsl(var(--heading-color)); 
            padding: 7px 16px !important;
            background-color: transparent;
            transition: .2s linear;
            border-radius: 8px;
            border: 1px solid hsl(var(--gray-five));
            text-transform: capitalize;
            font-weight: 500;
            overflow: hidden;
            @include font-14; 
            @include md-screen {
                padding: 5px 10px !important;
            }
            &.active {
                color: hsl(var(--white)) !important; 
                background-color: hsl(var(--heading-color)) !important;
                border-color: hsl(var(--heading-color)) !important;
                font-weight: 300;
            }
            &:hover {
                color: hsl(var(--main));
                background-color: hsl(var(--white));
            }
        }
    }

    /* Style Icon */
    &.style-icon {
        .nav-item {
            .nav-link {
                padding: 0 !important;
                border: 0 !important;
                background-color: transparent !important;
                overflow: unset;
                .tech-list {
                    &__item {
                        box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.25);
                        .text {
                            display: none;
                        }
                    }
                }
                &.active .tech-list {
                    &__item {
                        padding: 10px;
                        height: unset;
                        width: unset;
                        border-radius: 50px !important;
                        border: 1px solid hsl(var(--main));
                        background-color: hsl(var(--white));
                        box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.25);
                        color: hsl(var(--heading-color));
                        font-weight: 500;
                        .text {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
/* Style Gradient */
.tab-gradient {
    display: flex;
    align-items: center;
    gap: 16px;
    .nav-item {
        .nav-link {
            background-color: transparent !important;
            position: relative;
            z-index: 1;
            color: hsl(var(--heading-color));
            @include font-18; 
            text-transform: capitalize;
            font-weight: 400;
            padding: 14px 50px;
            @include lg-screen {
                padding: 10px 30px;
                @include font-16; 
            }
            &::before, &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: hsl(var(--white));
                left: 0;
                top: 0;
                border-radius: inherit;
                z-index: -1;
            }
            &::after {
                background: var(--main-gradient);
                visibility: hidden;
                opacity: 0;
            }
            &.active {
                color: hsl(var(--white));
                font-weight: 300;
                &::before {
                    visibility: hidden;
                    opacity: 0;
                }
                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

/* Bordered Tab */
.tab-bordered {
    gap: 32px;
    margin-bottom: 0px;
    @include lg-screen {
        gap: 24px;
    }
    @include md-screen {
        gap: 16px;
    }
    @include msm-screen {
        gap: 8px;
        margin-bottom: 24px;
    }
    .nav-link {
        background-color: transparent;
        border: 1px solid hsl(var(--gray-five));
        border-radius: 5px;
        color: hsl(var(--body-color));
        padding: 5px 8px;
        @media (min-width: 575px) {
            background-color: transparent;
            padding: 0;
            color: hsl(var(--body-color)) !important;
            font-size: 14px;
            padding-bottom: 16px !important;
            border: 0;
            border-bottom: 2px solid transparent;
            border-radius: 0;
        }
        &.active {
            background-color: transparent;
            color: hsl(var(--heading-color)) !important;
            font-weight: 600;
            border-color: hsl(var(--heading-color)) !important; 
            .star-rating__text {
                color: hsl(var(--heading-color)) !important;
                font-weight: 600;
            }
        }
    }
}
/* ================================= Tab Css End =========================== */
