/* ======================= Cart Payment Section Css Start ========================= */
.cart-payment {
    &__box {
        background-color: #544889;;
        background-color: hsl(var(--section-bg));
        padding: 84px 0;
        border-radius: 24px;
        @include md-screen {
            padding: 56px 0;
        }
        @include msm-screen {
            padding: 24px;
        }
    }
}

/* Payment Method */
.payment-method {
    margin-bottom: 72px;
    @include md-screen {
        margin-bottom: 60px;
    }
    @include sm-screen {
        margin-bottom: 40px;
    }
    &__item {
        .form-check-input:checked + .form-check-label {
            &::before {
                background: var(--main-gradient);
            }
            &::after {
                background: hsl(var(--static-white));
                width: calc(100% - 4px);
                height: calc(100% - 4px);
            }
        }
        .form-check-label {
            background-color: hsl(var(--gray-seven));
            position: relative;
            z-index: 1;
            border-radius: 4px;
            width: 94px;
            height: 64px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 4px;
            transition: .2s linear;
            width: 100%;
            cursor: pointer;
            &::before, &::after {
                border-radius: inherit;
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: #D9D9D9;
                z-index: -1;
                transition: .2s linear;
            }
            &::after {
                background: hsl(var(--gray-seven));
                width: calc(100% - 2px);
                height: calc(100% - 2px);
            }
        }
    }
    .slick-initialized.slick-slider .slick-slide {
        margin: 0 4px;
    }
}

.payment-method__wrapper {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .payment-method__item {
        width: 100px;
    }
}

/* Payment Method */

.cart-payment-card {
    position: relative;
    background: var(--main-gradient);
    padding: 24px;
    z-index: 1;
    border-radius: 16px;
    @include msm-screen {
        padding: 24px 16px;
    }
    &::before {
        position: absolute;
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: hsl(var(--white));
        left: 1px;
        top: 1px;
        border-radius: inherit;
        z-index: -1;
    }
}


.total-bill {
    background-color: hsl(var(--gray-seven));
    padding: 16px;
    border-radius: 4px;
}
/* ======================= Cart Payment Section Css End ========================= */