/* ======================== Element Css Start ========================== */
/* Curve Shape */
.curve-shape {
    position: absolute;
    bottom: calc(100% - 1px);
    left: 0;
    &.right {
        left: auto;
        right: 0;
    }
    @include xl-screen {
        max-width: 250px;
    }
    @include md-screen {
        max-width: 140px;
    }
}
/* Curve Shape */

/* Brush Element */
.element-brush {
    position: absolute;
    z-index: -1;
    left: 50px;
    top: 64%;
    animation: upDownRotate 15s linear infinite;
}

/* Element */
.element {
    position: absolute;
    left: 90px;
    bottom: 22%;
    animation: upDownRotate 15s linear infinite;
    z-index: -1;
    &.two {
        left: auto;
        right: 90px;
        bottom: auto;
        top: 22%;
        animation-delay: 3s;
    }
    &.three {
        left: auto;
        right: 16%;
        bottom: 14%;
        animation-delay: 5s;
    }
}

@keyframes upDownRotate {
    0%, 100% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(200px) rotate(360deg);
    }
    100% {
        transform: translateY(0px) rotate(720deg);
    }
}

/* Line Vector Element */
.line-vector {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    max-width: 46%;
    &.two {
        left: auto;
        right: 0;
    }
}
/* ======================== Element Css End ========================== */
